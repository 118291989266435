<template>
	<v-app>
		<v-main>
			<v-container v-if="car_help">
			<v-card
				class="mx-auto"
				width="450"
				:loading="loading"
				outlined
			>
			<v-card-text class="pa-10">
			<div class="appzag">
				Узнайте стоимость выкупа авто прямо сейчас!
				<a href="#app" class="arrow-bottom"></a>
			</div>
			<v-select
				:menu-props="{ maxHeight: '400' }"
				attach
				class="mt-4 mb-5"
				dense
				v-model="marka"
				:items="car_help"
				item-text="name"
				outlined
				label="Марка"
				hide-details="true"
			></v-select>
			<v-select attach
				class="mt-1 mb-5"
				dense
				v-model="model"
				:items="get_model_arr"
				outlined
				label="Модель"
				hide-details="true"
			></v-select>
			<v-select attach
				class="mt-1 mb-5"
				dense
				v-model="year"
				:items="year_arr"
				outlined
				label="Год выпуска"
				hide-details="true"
			></v-select>
			<v-checkbox
				class="mt-1"
				v-model="minus_koef.ne_na_hody"
				label="Не на ходу (требуется эвакуатор)"
				hide-details="true"
			></v-checkbox>
			<v-checkbox
				class="mt-1"
				v-model="minus_koef.bitaya"
				label="Битая (красить 4 элемента)"
				hide-details="true"
			></v-checkbox>
			<v-checkbox
				class="mt-1"
				v-model="minus_koef.silno_bitaya"
				label="Сильно битая (красить более 4 элементов)"
				hide-details="true"
			></v-checkbox>
			<v-checkbox
				class="mt-1 mb-5 lastcheck"
				v-model="minus_koef.credit"
				label="Кредитная"
				hide-details="true"
			></v-checkbox>
			<v-btn class="btn btn-blue" x-large width="100%" outlined dark @click="get_json">Узнать стоимость</v-btn>
			<div v-if="error" class="text-center mt-2 text-h5 red--text darken-1">
				Укажите параметры
			</div>
			<div v-if="summ" class="text-center mt-2 text-h6">
				{{summ}}
			</div>
			<div v-if="summ">
				<div class="text-center mt-5 mb-4 text-h6 svyaz">Оставьте заявку для точной оценки:</div>
				<v-text-field 
					class="mt-1"
					dense
					v-model="feedback.name"
					:rules="[rules.required]"
					outlined
					label="Ваше имя"
				></v-text-field>
				<v-text-field 
					class="mt-1"
					dense
					v-model="feedback.phone"
					:rules="[rules.required]"
					outlined
					label="Номер телефона"
					name="phone"
				></v-text-field>
				<v-text-field 
					class="mt-1"
					dense
					v-model="feedback.comment"
					outlined
					label="Комментарий"
				></v-text-field>
				<v-file-input
					v-model="files"
					dense
					label="Добавить фото"
					multiple
					accept="image/*"
					placeholder="Можно несколько"
					prepend-icon="mdi-paperclip"
					outlined
					:show-size="1000"
				>
					<template v-slot:selection="{ index, text }">
					<v-chip
						v-if="index < 2"
						dark
						label
						small
					>
						{{ text }}
					</v-chip>
					<span
						v-else-if="index === 2"
						class="overline grey--text text--darken-3 mx-2"
					>
						+{{ files.length - 2 }} файл(-ов)
					</span>
					</template>
				</v-file-input>
				<v-btn
					x-large
					color="success"
					width="100%"
					outlined
					dark
					v-if="request_btn"
					@click="send_request"
				>
					Отправить
				</v-btn>
			</div>
			</v-card-text>
		</v-card>
			</v-container>
		</v-main>
	</v-app>
</template>
<script>
import { VCard, VCardText, VApp, VMain, VContainer, VSelect, VCheckbox, VBtn, VTextField, VFileInput, VChip } from 'vuetify/lib'
import axios from 'axios'
export default {
	components: {
		VApp,
		VMain,
		VContainer,
		VSelect,
		VCheckbox,
		VBtn,
		VTextField,
		VFileInput,
		VChip,
		VCard,
		VCardText
	},
	data(){
		return{
			summ: null,
			loading:false,
			car_help:null,
			model:"",
			year:"",
			minus_koef:{
				ne_na_hody:false,
				bitaya:false,
				silno_bitaya:false,
				credit:false,
			},
			marka:"",
			error:false,
			year_arr: null,
			feedback: {
				phone: '',
				name: '',
				comment: ''
			},
			files:[],
			rules: {
				required: value => !!value || 'обязательное поле',
				counter: value => value.length <= 20 || 'Max 20 characters',
				email: value => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					return pattern.test(value) || 'Invalid e-mail.'
				},
			}
		}
	},
	mounted(){
		axios.get("/parser/api/dromcar.json")
		.then(response=>{
			this.car_help = response.data
		}); 
		let curYear = new Date().getFullYear();
		const startYear = 1984
		const yearArr = []
		for (curYear;startYear<curYear;curYear--) {
			yearArr.push(curYear)
		}
		this.year_arr = yearArr
	},
	computed:{
		get_model_arr(){
			if(this.marka!=""){
				let arr = this.car_help.find(e=>e.name==this.marka);
				// return arr.models;
				let models = []
				for (const key in arr.models) {
					models.push(arr.models[key])
				}
				models = models.sort()
				return models
			}else{
				return ['Выберите марку'];
			}
		},
		request_btn () {
			if (this.feedback.phone !== '' && this.feedback.name !== '') {
				return true
			} else {
				return false
			}
		}
	},
	methods:{
		get_json: async function(){
			if(this.marka==""||this.model==""||this.year==""){
				this.error = true;
			}else{
				this.error = false;
				let fm = new FormData();
				fm.append("marka",this.marka);
				fm.append("model",this.model);
				fm.append("year",this.year);
				this.loading = "#aaa";
				axios.post("/parser/api/get_data.php",fm)
				.then(response=>{
					console.log(response.data)
					if(response.data){
						this.fn_sort(response.data);
					}else{
						this.summ = "Нет информации";
						this.loading = false;
					}
					
				})
			}
		},
		fn_sort:function(data){
			data.sort(function(a, b) {return a - b;});
			if(data.length>5){
				data.pop();
				data.shift();
			}
			let minus_koef = 1;
			if(this.minus_koef.ne_na_hody){
				minus_koef = 0.8;
			}
			if(this.minus_koef.bitaya){
				minus_koef = 0.75;
			}
			if(this.minus_koef.silno_bitaya||this.minus_koef.credit){
				minus_koef = 0.6;
			}
			let drom_koef = 0.8;
			this.summ = "Примерная стоимость: "+((data.reduce((a, b) => (a + b)) / data.length)*drom_koef*minus_koef).toFixed();
			this.loading = false;

		},
		send_request () {
			if (this.request_btn) {
				this.loading = "#aaa";
				let fm = new FormData()
				for(const key in this.feedback) {
					fm.append(key, this.feedback[key])
				}
				for(const key in this.minus_koef) {
					fm.append(key, this.minus_koef[key])
				}
				fm.append('marka', this.marka)
				fm.append('model', this.model)
				fm.append('year', this.year)
				this.files.forEach((e, index) => {
					fm.append(`file_${index}`, e)
				});
				fm.append('summ', this.summ)
				axios.post('/parser/api/send_feedback.php',fm)
				.then((response) => {
					console.log(response)
					this.summ = null
					this.loading = false;
					this.marka = ''
					this.model = ''
					this.year = ''
					for(const key in this.minus_koef) {
						this.minus_koef[key] = false
					}
				})
			}
		}
	}
}
</script>
<template>
	<div id="app">
		<!-- <img alt="Vue logo" src="./assets/logo.png">
		<HelloWorld msg="Welcome to Your Vue.js App"/> -->
		<Calculator />
	</div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import Calculator from './components/Calc'

export default {
  name: 'App',
  components: {
    Calculator
  }
}
</script>
